import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import EventList from 'components/EventList';
import Text from 'components/Text';
import tw from 'twin.macro';
import Row from 'components/Row';

import { useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';
import { Box, Heading } from 'grommet';

const Avatar = tw.img`rounded-full border border-gray-100 shadow-sm h-20`;

const Events = () => {
  if (!isBrowser) return null;
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  if (!isLoading && isAuthenticated === false) {
    loginWithRedirect();
    return null;
  }
  const resp = useQuery(getEvents(user?.sub));
  return (
    <Layout>
      <div className="lg:w-10/12 mx-auto">
        <Box pad={{ vertical: 'medium', left: 'small', right: 'medium' }} gap="small">
          <Box gap="small" align="center" direction="row">
            <Avatar src={user?.picture} />
            <Heading level="3" color="brand">
              Hi {user?.nickname}
            </Heading>
          </Box>
          <Text label="Your Events" large spacing="sm" />
        </Box>
        <EventList
          cols={3}
          user={user?.sub}
          events={resp.data?.events}
          loading={resp.loading}
          to={({ id }) => {
            return `/host/event/dashboard/${id}/home/`;
          }}
        />
      </div>
    </Layout>
  );
};

export default Events;
