import { Box } from 'grommet';
import React from 'react';
import { EventCard } from 'tutadoo-design-system/src/components/EventCard';
import tw from 'twin.macro';
import styled from 'styled-components';
// import EventCard from './EventCard';
import EventCardAnimation from './EventCardAnimation';

const List = styled.div(() => [
  tw`grid grid-rows-1 grid-cols-1 pb-2 grid-flow-row`,
  tw`gap-4`,
  tw`md:grid-cols-2 lg:grid-cols-4`
]);

function EventList({ to, user, events, loading, ...rest }) {
  return (
    <List>
      {loading &&
        ['', '', '', '', '', '', '', '', '', '', '', ''].map(() => <EventCardAnimation />)}
      {events?.map((event) => (
        <EventCard
          key={event.id}
          event={event}
          viewLink={
            to
              ? to({ name: event.name, id: event.id })
              : `/event/${event.name
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')}/${event.id}/`
          }
          // to={to ? to({ name, id }) : `/event/${slugify(name)}/${id}/`}
          // title={name}
          // description={description}
          // ticket_types={ticket_types}
          // organizer={organizer}
          // background={image.includes('http') ? image : `https:${image}`}
          // start_date={start_date}
          // end_date={end_date}
        />
      ))}
    </List>
  );
}

export default EventList;
